import { Select } from "antd";
import axios from 'axios';
import { useEffect, useState } from 'react';
import './style.facilitymanagement.scss';
// import ReactApexChart from 'react-apexcharts';

import Logo from '../../../images/Logo.png';
import PolarAreaChart from "../../../components/Chart/PolarAreaChart";
import DoughnutChart from "../../../components/Chart/DoughnutChart";

// Register the plugin globally
// ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const lstColor = ['rgba(239, 83, 80, 0.7)', 'rgb(255, 167, 38, 0.7)', 'rgb(255, 213, 79, 0.7)', 'rgb(102, 187, 106, 0.7)', 'rgb(66, 165, 245, 0.7)', 'rgb(92, 107, 192, 0.7)', 'rgb(171, 71, 188, 0.7)', 'rgb(236, 64, 122, 0.7)']

const FacilityManagement = () => {

    const [lstSeries, setLstSeries] = useState<any[]>([])
    const [lstSeries2, setLstSeries2] = useState<any[]>([])
    const [options, setOptions] = useState<any>({})
    const [optionsDonut, setOptionsDonut] = useState<any>({})
    const [options2, setOptions2] = useState<any>({})
    const [options2Donut, setOptions2Donut] = useState<any>({})
    const [yearSelected, setYearSelected] = useState<string>('2025')
    const [data, setData] = useState<any[]>([])
    const [dataDonut, setDataDonut] = useState<any>()
    const [data2, setData2] = useState<any[]>([])
    const [data2Donut, setData2Donut] = useState<any>()
    const [lstData, setLstData] = useState<any>()
    const [lstData2, setLstData2] = useState<any>()
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

    // Kiểm tra khi màn hình thay đổi kích thước
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        console.log(windowSize);

        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    useEffect(() => {
        getSeries()
        getSeries2()
    }, [yearSelected])

    useEffect(() => {
        console.log(data);
        if (data.length === 0) return
        const dataSort = data.sort((a, b) => {
            if (a.criteriaId < b.criteriaId) {
                return -1;
            }
            if (a.criteriaId > b.criteriaId) {
                return 1;
            }
            return 0;
        });
        console.log(dataSort);


        const series = dataSort.map((item: any) => item.setOfQuestions)
        console.log(series);

        // Cho series thành mảng 1 chiều
        let series1D: any[] = []
        series.forEach((item) => {
            item.forEach((item1: any) => {
                series1D.push(Math.round(item1.average * 10) / 10)
            })
        })
        console.log(series1D);
        setLstSeries(series1D)

        let labels: any[] = []
        series.forEach((item) => {
            item.forEach((item1: any) => {
                labels.push(item1.setOfQuestionDes)
            })
        })
        console.log(labels);


        // Tạo ra mảng màu theo số lượng series, mỗi series[0], series[1] có 1 màu, còn serti[0][0] sẽ trùng với màu của series[0]
        let colors: string[] = []
        series.forEach((item, index) => {
            // Lấy ra màu ngẫu nhiêu để cho vào mảng colors
            item.forEach(() => {
                // colors.push('#FF0000')
                // colors sẽ lấy ra màu ngẫu nhiên
                colors.push(lstColor[index])
            })
        })
        console.log(colors);

        const options = {
            responsive: true,
            scales: {
                r: {
                    suggestedMin: 0,
                    suggestedMax: 5,
                    grid: {
                        circular: true,
                        colors: '#000',
                    },
                    angleLines: {
                        display: true,
                        color: '#00000',
                        lineWidth: 1,
                    },
                    ticks: {
                        stepSize: 1,
                        beginAtZero: true,
                        backdropColor: 'transparent',
                        font: {
                            size: 19,
                        },
                        color: '#000',
                    },
                    lineArc: true,

                },
            },
            plugins: {
                legend: {
                    display: false
                },

                dataLabels: {
                    // color: '#000',
                    display: false,

                    // formatter: (value: any, context: any) => {
                    //     return ''; // Display the value in the label
                    // },
                },
            },
            cutout: '50%',
            layout: {
                padding: 5
            },
            hover: {
                mode: 'nearest'
            },
            animation: {
                duration: 2000
            },
            tooltips: {
                enabled: true,
            },
        };

        const optionsDonut = {
            cutout: '80%', // Adjust the size of the inner hole
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    color: (context: any) => {
                        return lstColor[context.dataIndex];
                    },
                    rotation: (context: any) => {
                        switch (context.dataIndex) {
                            case 0:
                                return 15;
                            case 1:
                                return 55;
                            case 2:
                                return 100;
                            case 3:
                                return 155;
                            case 4:
                                return 200;
                            case 5:
                                return 245;
                            case 6:
                                return 290;
                            case 7:
                                return 335;
                            default:
                                return 0;
                        }
                    },
                    display: true,

                    formatter: (value: any, context: any, index: any) => {
                        console.log(context.dataIndex);
                        console.log(dataSort[context.dataIndex].criteriaName);
                        //Kiểm tra nếu
                        let label = dataSort[context.dataIndex].criteriaName;
                        if (label.length > 30) {
                            label = label.substring(0, 30) + '...';
                        }
                        return label; // Display the value in the label
                    },
                    font: {
                        size: windowSize[0] > 400 ? 12 : 6, // Set the font size for data labels
                        fontWeight: 'bold',
                    },
                },
            },
        }

        const dataTmp = {
            labels: labels,
            datasets: [
                {
                    label: 'Điểm: ',
                    data: series1D,
                    backgroundColor: colors,
                    borderColor: '#000',
                    borderWidth: 1,
                    innerRadius: 50,
                    indexAxis: 'r',
                },
            ],
        }

        const dataDonutTmp = {
            labels: dataSort.map((item: any) => item.criteriaName),
            datasets: [
                {
                    label: 'Điểm: ',
                    data: [4, 5, 5, 6, 5, 5, 5, 6],
                    backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                    borderColor: '#000',
                    borderWidth: 1,
                    innerRadius: 90,
                    indexAxis: 'r',
                },
            ],
        }

        console.log(dataDonutTmp);


        setOptions(options)
        setOptionsDonut(optionsDonut)
        setDataDonut(dataDonutTmp)
        setLstData(dataTmp)
    }, [data])

    useEffect(() => {
        if (data2.length === 0) return

        const series = data2.map((item: any) => item.setOfQuestions)
        console.log(series);

        // Cho series thành mảng 1 chiều
        let series1D: any[] = []
        series.forEach((item) => {
            item.forEach((item1: any) => {
                series1D.push(Math.round(item1.average * 100) / 100)
            })
        })
        console.log(series1D);
        setLstSeries2(series1D)

        let labels: any[] = []
        series.forEach((item) => {
            item.forEach((item1: any) => {
                labels.push(item1.setOfQuestionDes)
            })
        })
        console.log(labels);


        // Tạo ra mảng màu theo số lượng series, mỗi series[0], series[1] có 1 màu, còn serti[0][0] sẽ trùng với màu của series[0]
        let colors: string[] = []
        series.forEach((item, index) => {
            // Lấy ra màu ngẫu nhiêu để cho vào mảng colors
            item.forEach(() => {
                // colors.push('#FF0000')
                // colors sẽ lấy ra màu ngẫu nhiên
                colors.push(lstColor[index])
            })
        })
        console.log(colors);

        const options = {
            responsive: true,
            scales: {
                r: {
                    suggestedMin: 0,
                    suggestedMax: 5,
                    grid: {
                        circular: true,
                        colors: '#000',
                    },
                    angleLines: {
                        display: true,
                        color: '#000',
                        lineWidth: 1,
                    },
                    ticks: {
                        stepSize: 1,
                        beginAtZero: true,
                        backdropColor: 'transparent',
                        font: {
                            size: 19,
                        },
                        color: '#000',
                    },
                    lineArc: true,

                },
            },
            plugins: {
                legend: {
                    display: false

                },
                datalabels: {
                    color: '#000',
                    rotation: (context: any) => {
                        switch (context.dataIndex) {
                            case 0:
                                return 15;
                            case 1:
                                return 55;
                            case 2:
                                return 100;
                            case 3:
                                return 155;
                            case 4:
                                return 200;
                            case 5:
                                return 245;
                            case 6:
                                return 290;
                            case 7:
                                return 335;
                            default:
                                return 0;
                        }
                    },
                    display: true,
                    formatter: (value: any, context: any, index: any) => {
                        console.log(context.dataIndex);
                        return data2[context.dataIndex].criteriaName; // Display the value in the label
                    },
                    font: {
                        size: windowSize[0] > 400 ? 12 : 6, // Set the font size for data labels
                        fontWeight: 'bold',
                    },
                },
            },
            cutout: '80%',
            layout: {
                padding: 10
            },
            hover: {
                mode: 'nearest'
            },
            animation: {
                duration: 2000
            },
            tooltips: {
                enabled: true,
            },
        };

        const optionsDonut = {
            cutout: '80%', // Adjust the size of the inner hole
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    color: (context: any) => {
                        return lstColor[context.dataIndex];
                    },
                    rotation: (context: any) => {
                        switch (context.dataIndex) {
                            case 0:
                                return 15;
                            case 1:
                                return 55;
                            case 2:
                                return 100;
                            case 3:
                                return 155;
                            case 4:
                                return 200;
                            case 5:
                                return 245;
                            case 6:
                                return 290;
                            case 7:
                                return 335;
                            default:
                                return 0;
                        }
                    },
                    display: true,

                    formatter: (value: any, context: any, index: any) => {
                        console.log(context.dataIndex);
                        console.log(data2[context.dataIndex].criteriaName);
                        //Kiểm tra nếu
                        let label = data2[context.dataIndex].criteriaName;
                        if (label.length > 30) {
                            label = label.substring(0, 30) + '...';
                        }
                        return label; // Display the value in the label
                    },
                    font: {
                        size: windowSize[0] > 400 ? 12 : 6, // Set the font size for data labels
                    },

                },
            },
        }

        const dataTmp = {
            labels: labels,
            datasets: [
                {
                    label: 'Điểm: ',
                    data: series1D,
                    backgroundColor: colors,
                    borderColor: '#000',
                    borderWidth: 1,
                    innerRadius: 50,
                    indexAxis: 'r',
                },
            ],
        }

        const dataDonutTmp = {
            labels: data2.map((item: any) => item.criteriaName),
            datasets: [
                {
                    label: 'Điểm: ',
                    data: [4, 5, 5, 6, 5, 5, 5, 6],
                    backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                    borderColor: '#000',
                    borderWidth: 1,
                    innerRadius: 90,
                    indexAxis: 'r',
                },
            ],
        }

        setOptions2(options)
        setOptions2Donut(optionsDonut)
        setLstData2(dataTmp)
        setData2Donut(dataDonutTmp)

    }, [data2])

    const getSeries = async () => {
        await axios.get(`https://api.vinnovate.vn/statistics/admin-statistics-by-year/${yearSelected}/UINNOVATE`)
            .then((res) => {
                console.log(res.data);
                const dataSort = res.data.data.sort((a: any, b: any) => {
                    if (a.criteriaId < b.criteriaId) {
                        return -1;
                    }
                    if (a.criteriaId > b.criteriaId) {
                        return 1;
                    }
                    return 0;
                });
                console.log(dataSort);

                setData(dataSort)

            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getSeries2 = async () => {
        await axios.get(`https://api.vinnovate.vn/statistics/admin-statistics-by-year/${yearSelected}/UINNOVATE_SHORT`)
            .then((res) => {
                console.log(res.data);
                const dataSort = res.data.data.sort((a: any, b: any) => {
                    if (a.criteriaId < b.criteriaId) {
                        return -1;
                    }
                    if (a.criteriaId > b.criteriaId) {
                        return 1;
                    }
                    return 0;
                });

                setData2(dataSort)

            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <div className='year-management'>
            <div className='usermanagement-title'>Thống kê theo năm</div>
            <div className='usermanagement-filter'>
                <Select
                    placeholder="Chọn năm"
                    style={{ width: 200 }}
                    defaultValue={'2025'}
                    onChange={(value) => setYearSelected(value)}
                >
                    <Select.Option value="2020">2020</Select.Option>
                    <Select.Option value="2021">2021</Select.Option>
                    <Select.Option value="2022">2022</Select.Option>
                    <Select.Option value="2023">2023</Select.Option>
                    <Select.Option value="2024">2024</Select.Option>
                    <Select.Option value="2025">2025</Select.Option>
                </Select>
            </div>

            <div className='charts'>
                <div style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: 20

                }}>
                    Biểu đồ UINNOVATE
                </div>
                {(lstData && dataDonut) &&
                    // <ReactApexChart options={options2} series={lstSeries2} type="polarArea" height={650} />
                    <div className='chart'>
                        <div className="chart-polar">
                            <PolarAreaChart data={lstData} options={options} />
                            <div className="logo-chart">
                                <img src={Logo} alt="" />
                            </div>
                        </div>
                        <div className="chart-dough">
                            <DoughnutChart data={dataDonut} options={optionsDonut} />
                        </div>
                        {/* <SunburstChart data={data1} /> */}
                    </div>
                }
                {/* <div className='labels'>
                    {
                        lstColor.map((item, index) => {
                            return (
                                <div className='labels__item' key={index}>
                                    <div className='labels__item__color' style={{ backgroundColor: item }}></div>
                                    <div className='labels__item__text'>
                                        {data[index]?.criteriaName}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> */}
            </div>
            <div className='charts'>
                <div style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: 20

                }}>
                    Biểu đồ UINNOVATE RÚT GỌN
                </div>
                {(lstData2 && data2Donut) &&
                    // <ReactApexChart options={options2} series={lstSeries2} type="polarArea" height={650} />
                    <div className='chart'>
                        <div className="chart-polar">
                            <PolarAreaChart data={lstData2} options={options} />
                            <div className="logo-chart">
                                <img src={Logo} alt="" />
                            </div>
                        </div>
                        <div className="chart-dough">
                            <DoughnutChart data={data2Donut} options={options2Donut} />
                        </div>
                        {/* <SunburstChart data={data1} /> */}
                    </div>
                }
                {/* <div className='labels'>
                    {
                        lstColor.map((item, index) => {
                            return (
                                <div className='labels__item' key={index}>
                                    <div className='labels__item__color' style={{ backgroundColor: item }}></div>
                                    <div className='labels__item__text'>
                                        {data[index]?.criteriaName}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> */}
            </div>
            {/* <div className='usermanagement-title'>Tổng hợp dữ liệu lịch sử</div>
            <div className='charts'>
                <ReactApexChart options={options2} series={series2} type="line" height={350} />
            </div> */}

        </div >
    )
}

export default FacilityManagement
