import React from 'react'
import { Breadcrumb, Button, Input, Pagination, Select, Spin } from "antd";
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import axios from 'axios';
import './style.userstatistics.scss'
import Logo from '../../images/Logo.png';
// import ReactApexChart from 'react-apexcharts';

import { PolarArea } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
    // scales,
} from 'chart.js';
import { getUserInfoRequest } from '../../redux/controller';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
import ResultAPI from '../../api/results/results.api';
import SYSTEM_CONSTANTS from '../../common/constants';
import PolarAreaChart from '../../components/Chart/PolarAreaChart';
import DoughnutChart from '../../components/Chart/DoughnutChart';
import RadarChart from '../../components/Chart/RadarChart';
import RadarAmChart from '../../components/Chart/RadarAmChart';
import RadarAmChartSingle from '../../components/Chart/RadarAmChartSingle';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);
// ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const lstColor = ['rgba(239, 83, 80, 0.7)', 'rgb(255, 167, 38, 0.7)', 'rgb(255, 213, 79, 0.7)', 'rgb(102, 187, 106, 0.7)', 'rgb(66, 165, 245, 0.7)', 'rgb(92, 107, 192, 0.7)', 'rgb(171, 71, 188, 0.7)', 'rgb(236, 64, 122, 0.7)']
const UserStatistics = () => {

    const [options, setOptions] = useState<any>({})
    const [optionsDonut, setOptionsDonut] = useState<any>({})
    const [yearSelected, setYearSelected] = useState<string>('2025')
    const [data, setData] = useState<any[]>([])
    const [dataDonut, setDataDonut] = useState<any>(null)
    const [userType, setUserType] = useState<string>('')
    const dispatch = useDispatchRoot()
    const { user } = useSelectorRoot((state) => state.login);
    const [systemStatistics, setSystemStatistics] = useState<any>()
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const [lstSeries, setLstSeries] = useState<any>()
    const [lstData, setLstData] = useState<any>(null)
    const [dataRadio, setDataRadio] = useState<any>(null)
    const [optionsRadio, setOptionsRadio] = useState<any>(null)

    const [data1, setData1] = useState<any>(null)
    const [dataRadio1, setDataRadio1] = useState<any>(null)
    const [optionsRadio1, setOptionsRadio1] = useState<any>(null)
    const [dataRadio2, setDataRadio2] = useState<any>(null)
    const [optionsRadio2, setOptionsRadio2] = useState<any>(null)
    const [dataRadio3, setDataRadio3] = useState<any>(null)
    const [optionsRadio3, setOptionsRadio3] = useState<any>(null)
    const [data1Donut, setData1Donut] = useState<any>(null)
    const [options1Donut, setOptions1Donut] = useState<any>(null)
    const [lstChartData, setLstChartData] = useState<Array<{
        name: string;
        value1: number;
        value2: number;
        value3: number;
    }>>([]);
    const [singleChartData, setSingleChartData] = useState<Array<{ category: string, value: number }>>([]);

    // Kiểm tra khi màn hình thay đổi kích thước
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        console.log(windowSize);

        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    useEffect(() => {
        user && fetchDataSystemStatistics();
        if (user && user.type === 'UINNOVATE_SHORT' || user && user.type === 'UINNOVATE') {
            fetchNumberUser();
        }
    }, [yearSelected])

    useEffect(() => {
        let type = localStorage.getItem('userType') ? localStorage.getItem('userType') : '';
        let token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
        if (type) {
            type = type.slice(1);
            type = type.slice(0, type.length - 1);

            if (type === 'UINNOVATE_SHORT') {
                type = 'UINNOVATE';
            }
            setUserType(type);

        }
        if (token) {
            token = token.slice(1);
            token = token.slice(0, token.length - 1);
            dispatch(getUserInfoRequest(token))
        }
    }, [])

    useEffect(() => {
        console.log(user);

        user &&
            fetchDataSystemStatistics();

        if (user && user.type === 'UINNOVATE_SHORT' || user && user.type === 'UINNOVATE') {
            fetchNumberUser();
        }
    }, [user])


    useEffect(() => {
        console.log(data);
        if (data.length === 0) return

        if (userType === 'UINNOVATE' || userType === 'UINNOVATE_SHORT') {
            const dataSort = data.sort((a: any, b: any) => {
                if (a.criteriaId < b.criteriaId) {
                    return -1;
                }
                if (a.criteriaId > b.criteriaId) {
                    return 1;
                }
                return 0;
            });
            console.log(dataSort);


            const series = dataSort.map((item: any) => item.setOfQuestions)
            console.log(series);

            // Cho series thành mảng 1 chiều
            let series1D: any[] = []
            series.forEach((item) => {
                item.forEach((item1: any) => {
                    series1D.push(Math.round(item1.average * 10) / 10)
                })
            })
            console.log(series1D);
            setLstSeries(series1D)

            let labels: any[] = []
            series.forEach((item) => {
                item.forEach((item1: any) => {
                    labels.push(item1.setOfQuestionDes)
                })
            })
            console.log(labels);


            // Tạo ra mảng màu theo số lượng series, mỗi series[0], series[1] có 1 màu, còn serti[0][0] sẽ trùng với màu của series[0]
            let colors: string[] = []
            series.forEach((item, index) => {
                // Lấy ra màu ngẫu nhiêu để cho vào mảng colors
                item.forEach(() => {
                    // colors.push('#FF0000')
                    // colors sẽ lấy ra màu ngẫu nhiên
                    colors.push(lstColor[index])
                })
            })
            console.log(colors);

            const options = {
                responsive: true,
                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            circular: true,
                            colors: '#000',
                        },
                        angleLines: {
                            display: true,
                            color: '#00000',
                            lineWidth: 1,
                        },
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                            backdropColor: 'transparent',
                            font: {
                                size: 19,
                            },
                            color: '#000',
                        },
                        lineArc: true,

                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },

                    dataLabels: {
                        // color: '#000',
                        display: false,

                        // formatter: (value: any, context: any) => {
                        //     return ''; // Display the value in the label
                        // },
                    },
                },
                cutout: '50%',
                layout: {
                    padding: 5
                },
                hover: {
                    mode: 'nearest'
                },
                animation: {
                    duration: 2000
                },
                tooltips: {
                    enabled: true,
                },
            };

            const optionsDonut = {
                cutout: '80%', // Adjust the size of the inner hole
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: (context: any) => {
                            return lstColor[context.dataIndex];
                        },
                        rotation: (context: any) => {
                            switch (context.dataIndex) {
                                case 0:
                                    return 15;
                                case 1:
                                    return 55;
                                case 2:
                                    return 100;
                                case 3:
                                    return 155;
                                case 4:
                                    return 200;
                                case 5:
                                    return 245;
                                case 6:
                                    return 290;
                                case 7:
                                    return 335;
                                default:
                                    return 0;
                            }
                        },
                        display: true,

                        formatter: (value: any, context: any, index: any) => {
                            console.log(context.dataIndex);
                            console.log(dataSort[context.dataIndex].criteriaName);
                            //Kiểm tra nếu
                            let label = dataSort[context.dataIndex].criteriaName;
                            if (label.length > 30) {
                                label = label.substring(0, 30) + '...';
                            }
                            return label; // Display the value in the label
                        },
                        font: {
                            size: windowSize[0] > 400 ? 12 : 6, // Set the font size for data labels
                            fontWeight: 'bold',
                        },
                    },
                },
            }

            const optionsRadio = {
                responsive: true,
                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            display: true
                        },
                        angleLines: {
                            display: true
                        },
                        ticks: {
                            display: true,
                            stepSize: 1 // Cho khoảng cách value là 1
                        },
                        lineArc: true,

                        pointLabels: {
                            display: false, // Ẩn nhãn xung quanh biểu đồ
                        },
                        // grid: {
                        //     display: false, // Tùy chọn: Ẩn các đường lưới
                        // },
                        // angleLines: {
                        //     display: false, // Tùy chọn: Ẩn các đường hướng tâm
                        // },
                        // ticks: {
                        //     display: false, // Tùy chọn: Ẩn các chỉ số mức độ
                        // }

                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },

                    dataLabels: {
                        // color: '#000',
                        display: false,

                        // formatter: (value: any, context: any) => {
                        //     return ''; // Display the value in the label
                        // },
                    }

                },
            }

            const dataTmp = {
                labels: labels,
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: series1D,
                        backgroundColor: colors,
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataDonutTmp = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: [4, 5, 5, 6, 5, 5, 5, 6],
                        backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 90,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataDonutTmp2 = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: [5, 5, 5, 5, 5, 5, 5, 5],
                        backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 90,
                        indexAxis: 'r',
                    },
                ],
            }

            const tmpDataRadio: any[] = []

            data1.statisticOfFacilityLong && data1.statisticOfFacilityLong.listAverageOfCriterias.map((item: any) => {
                tmpDataRadio.push(Math.round(item.averageOfCriteria * 10) / 10)
            })

            console.log('tmpDataRadio', tmpDataRadio);


            const tmp2DataRadio: any[] = []

            data1.statisticOfFacilityShort && data1.statisticOfFacilityShort.listAverageOfCriterias.map((item: any) => {
                tmp2DataRadio.push(Math.round(item.averageOfCriteria * 10) / 10)
            })

            console.log('tmp2DataRadio', tmp2DataRadio);

            const tmp3DataRadio: any[] = []
            for (let i = 0; i < 8; i++) {
                let number1 = 0;
                let number2 = 0;
                let numberUser = 0
                if (data1.statisticOfFacilityLong && data1.statisticOfFacilityLong.listAverageOfCriterias[i]) {
                    number1 = (data1.statisticOfFacilityLong.listAverageOfCriterias[i].averageOfCriteria) * data1.statisticOfFacilityLong.listAverageOfCriterias[i].numberOfUser
                    numberUser += data1.statisticOfFacilityLong.listAverageOfCriterias[i].numberOfUser
                }
                if (data1.statisticOfFacilityShort && data1.statisticOfFacilityShort.listAverageOfCriterias[i]) {
                    number2 = (data1.statisticOfFacilityShort.listAverageOfCriterias[i].averageOfCriteria) * data1.statisticOfFacilityShort.listAverageOfCriterias[i].numberOfUser
                    numberUser += data1.statisticOfFacilityShort.listAverageOfCriterias[i].numberOfUser
                }
                console.log('tmp3DataRadio number', number1, number2, numberUser);


                tmp3DataRadio.push(Math.round((number1 + number2) / numberUser * 10) / 10)
            }

            const dataRadioTmp = {
                labels: data1.statisticOfFacilityLong && data1.statisticOfFacilityLong.listAverageOfCriterias.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: tmpDataRadio,
                        backgroundColor: ['rgba(254, 246, 169, 0.5)', 'rgba(254, 246, 169, 0.5)', 'rgba(254, 246, 169, 0.5)', 'rgba(254, 246, 169, 0.5)', 'rgba(254, 246, 169, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataRadioTmp2 = {
                labels: data1.statisticOfFacilityShort && data1.statisticOfFacilityShort.listAverageOfCriterias.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: tmp2DataRadio,
                        backgroundColor: ['rgba(189, 248, 239, 0.5)', 'rgba(189, 248, 239, 0.5)', 'rgba(189, 248, 239, 0.5)', 'rgba(189, 248, 239, 0.5)', 'rgba(189, 248, 239, 0.5)', 'rgba(189, 248, 239, 0.5)', 'rgba(189, 248, 239, 0.5)', 'rgba(189, 248, 239, 0.5)'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataRadioTmp3 = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: tmp3DataRadio,
                        backgroundColor: ['rgba(252, 89, 126, 0.5)', 'rgba(252, 89, 126, 0.5)', 'rgba(252, 89, 126, 0.5)', 'rgba(252, 89, 126, 0.5)', 'rgba(252, 89, 126, 0.5)', 'rgba(252, 89, 126, 0.5)', 'rgba(252, 89, 126, 0.5)', 'rgba(252, 89, 126, 0.5)'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }

            console.log('dataRadioTmp', dataRadioTmp);
            console.log('dataRadioTmp2', dataRadioTmp2);
            console.log('dataRadioTmp3', dataRadioTmp3);



            setOptions(options)
            setOptionsDonut(optionsDonut)
            setDataDonut(dataDonutTmp)
            setData1Donut(dataDonutTmp2)
            setLstData(dataTmp)
            setDataRadio1(dataRadioTmp)
            setDataRadio2(dataRadioTmp2)
            setOptionsRadio1(optionsRadio)
            setOptionsRadio2(optionsRadio)
            setDataRadio3(dataRadioTmp3)
            setOptionsRadio3(optionsRadio)

        }

        else if (userType === 'UIMPACT') {
            const dataSort = data.sort((a: any, b: any) => {
                if (a.criteriaId < b.criteriaId) {
                    return -1;
                }
                if (a.criteriaId > b.criteriaId) {
                    return 1;
                }
                return 0;
            });
            console.log(dataSort[0].criteriaName);


            const series = dataSort.map((item: any) => item.setOfQuestions)
            console.log(series);

            // Cho series thành mảng 1 chiều
            let series1D: any[] = []
            series.forEach((item) => {
                item.forEach((item1: any) => {
                    series1D.push(Math.round(item1.average * 10) / 10)
                })
            })
            console.log(series1D);
            setLstSeries(series1D)

            let labels: any[] = []
            series.forEach((item) => {
                item.forEach((item1: any) => {
                    labels.push(item1.name)
                })
            })
            console.log(labels);


            // Tạo ra mảng màu theo số lượng series, mỗi series[0], series[1] có 1 màu, còn serti[0][0] sẽ trùng với màu của series[0]
            let colors: string[] = []
            series.forEach((item, index) => {
                // Lấy ra màu ngẫu nhiêu để cho vào mảng colors
                item.forEach(() => {
                    // colors.push('#FF0000')
                    // colors sẽ lấy ra màu ngẫu nhiên
                    colors.push(lstColor[index])
                })
            })
            console.log(colors);

            const options = {
                responsive: true,
                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            circular: true,
                            colors: '#000',
                        },
                        angleLines: {
                            display: true,
                            color: '#00000',
                            lineWidth: 1,
                        },
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                            backdropColor: 'transparent',
                            font: {
                                size: 19,
                            },
                            color: '#000',
                        },
                        lineArc: true,

                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },

                    dataLabels: {
                        // color: '#000',
                        display: false,

                        // formatter: (value: any, context: any) => {
                        //     return ''; // Display the value in the label
                        // },
                    },
                },
                cutout: '50%',
                layout: {
                    padding: 5
                },
                hover: {
                    mode: 'nearest'
                },
                animation: {
                    duration: 2000
                },
                tooltips: {
                    enabled: true,
                },
            };

            const optionsDonut = {
                cutout: '80%', // Adjust the size of the inner hole
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: (context: any) => {
                            return lstColor[context.dataIndex];
                        },
                        rotation: (context: any) => {
                            switch (context.dataIndex) {
                                case 0:
                                    return 55;
                                case 1:
                                    return 140;
                                case 2:
                                    return 230;
                                case 3:
                                    return 320;
                                default:
                                    return 0;
                            }
                        },
                        display: true,

                        formatter: (value: any, context: any, index: any) => {
                            console.log(context.dataIndex);
                            console.log(dataSort[context.dataIndex].criteriaName);
                            //Kiểm tra nếu
                            let label = dataSort[context.dataIndex].criteriaName;
                            if (label.length > 30) {
                                label = label.substring(0, 30) + '...';
                            }
                            return label; // Display the value in the label
                        },
                        font: {
                            size: windowSize[0] > 400 ? 12 : 6, // Set the font size for data labels
                            fontWeight: 'bold',
                        },
                    },
                },
            }

            const dataTmp = {
                labels: labels,
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: series1D,
                        backgroundColor: colors,
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataDonutTmp = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: [series[0].length, series[1].length, series[2].length, series[3].length],
                        backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 90,
                        indexAxis: 'r',
                    },
                ],
            }

            console.log(dataDonutTmp);


            setOptions(options)
            setOptionsDonut(optionsDonut)
            setDataDonut(dataDonutTmp)
            setLstData(dataTmp)
        }

        else if (userType === 'PINNOVATE') {

            const dataSort = data.sort((a: any, b: any) => {
                if (a.criteriaId < b.criteriaId) {
                    return -1;
                }
                if (a.criteriaId > b.criteriaId) {
                    return 1;
                }
                return 0;
            });
            console.log(dataSort[0].criteriaName);


            const series = dataSort.map((item: any) => item.setOfQuestions)
            console.log(series[0].length);

            // Cho series thành mảng 1 chiều
            let series1D: any[] = []
            series.forEach((item) => {
                item.forEach((item1: any) => {
                    series1D.push(Math.round(item1.average * 10) / 10)
                })
            })
            console.log(series1D);
            setLstSeries(series1D)

            let labels: any[] = []
            series.forEach((item) => {
                item.forEach((item1: any) => {
                    labels.push(item1.name)
                })
            })
            console.log(labels);


            // Tạo ra mảng màu theo số lượng series, mỗi series[0], series[1] có 1 màu, còn serti[0][0] sẽ trùng với màu của series[0]
            let colors: string[] = []
            series.forEach((item, index) => {
                // Lấy ra màu ngẫu nhiêu để cho vào mảng colors
                item.forEach(() => {
                    // colors.push('#FF0000')
                    // colors sẽ lấy ra màu ngẫu nhiên
                    colors.push(lstColor[index])
                })
            })
            console.log(colors);

            const options = {
                responsive: true,
                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            circular: true,
                            colors: '#000',
                        },
                        angleLines: {
                            display: true,
                            color: '#00000',
                            lineWidth: 1,
                        },
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                            backdropColor: 'transparent',
                            font: {
                                size: 19,
                            },
                            color: '#000',
                        },
                        lineArc: true,

                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },

                    dataLabels: {
                        // color: '#000',
                        display: false,

                        // formatter: (value: any, context: any) => {
                        //     return ''; // Display the value in the label
                        // },
                    },
                },
                cutout: '50%',
                layout: {
                    padding: 5
                },
                hover: {
                    mode: 'nearest'
                },
                animation: {
                    duration: 2000
                },
                tooltips: {
                    enabled: true,
                },
            };

            const optionsDonut = {
                cutout: '80%', // Adjust the size of the inner hole
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: (context: any) => {
                            return lstColor[context.dataIndex];
                        },
                        rotation: (context: any) => {
                            switch (context.dataIndex) {
                                case 0:
                                    return 55;
                                case 1:
                                    return 170;
                                case 2:
                                    return 300;
                                default:
                                    return 0;
                            }
                        },
                        display: true,

                        formatter: (value: any, context: any, index: any) => {
                            console.log(context.dataIndex);
                            console.log(dataSort[context.dataIndex].criteriaName);
                            //Kiểm tra nếu
                            let label = dataSort[context.dataIndex].criteriaName;
                            if (label.length > 30) {
                                label = label.substring(0, 30) + '...';
                            }
                            return label; // Display the value in the label
                        },
                        font: {
                            size: windowSize[0] > 400 ? 12 : 6, // Set the font size for data labels
                            fontWeight: 'bold',
                        },
                    },
                },
            }

            const dataTmp = {
                labels: labels,
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: series1D,
                        backgroundColor: colors,
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataDonutTmp = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: [series[0].length, series[1].length, series[2].length],
                        backgroundColor: ['#ffffff', '#ffffff', '#ffffff'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 90,
                        indexAxis: 'r',
                    },
                ],
            }

            console.log(dataDonutTmp);


            setOptions(options)
            setOptionsDonut(optionsDonut)
            setDataDonut(dataDonutTmp)
            setLstData(dataTmp)
        }
    }, [data, data1])

    useEffect(() => {
        console.log(lstData);
        console.log(dataDonut);

    }, [lstData, dataDonut])

    useEffect(() => {
        if (!data1 || !data) return;

        console.log(data);


        // Chuẩn bị dữ liệu cho RadarAmChart
        const chartData = data.map((item: any, index: number) => {
            let value1 = 0;
            let value2 = 0;
            let value3 = 0;

            // Lấy giá trị longform
            if (data1.statisticOfFacilityLong &&
                data1.statisticOfFacilityLong.listAverageOfCriterias[index]) {
                value1 = Math.round(data1.statisticOfFacilityLong.listAverageOfCriterias[index].averageOfCriteria * 10) / 10;
            }

            // Lấy giá trị shortform
            if (data1.statisticOfFacilityShort &&
                data1.statisticOfFacilityShort.listAverageOfCriterias[index]) {
                value2 = Math.round(data1.statisticOfFacilityShort.listAverageOfCriterias[index].averageOfCriteria * 10) / 10;
            }

            // Tính giá trị trung bình
            // const numberUser1 = data1.statisticOfFacilityLong?.listAverageOfCriterias[index]?.numberOfUser || 0;
            // const numberUser2 = data1.statisticOfFacilityShort?.listAverageOfCriterias[index]?.numberOfUser || 0;
            // const totalUsers = numberUser1 + numberUser2;

            // if (totalUsers > 0) {
            //     value3 = Math.round(((value1 * numberUser1 + value2 * numberUser2) / totalUsers) * 10) / 10;
            // }

            value3 = item.setOfQuestions.reduce((sum: number, item: any) => sum + item.average, 0) / item.setOfQuestions.length;

            return {
                name: item.criteriaName,
                value1,
                value2,
                value3
            };
        });

        console.log('chartData', chartData);

        setLstChartData(chartData);
    }, [data1, data]);

    useEffect(() => {
        if (!dataRadio3) return;

        const singleChartData = dataRadio3.labels.map((label: string, index: number) => ({
            category: label,
            value: dataRadio3.datasets[0].data[index]
        }));

        setSingleChartData(singleChartData);
    }, [dataRadio3]);

    // useEffect(() => {
    //     console.log(data1);
    //     if (data1 && data.length === 0) return

    //     if (userType === 'UINNOVATE' || userType === 'UINNOVATE_SHORT') {
    //         const dataSort = data.sort((a: any, b: any) => {
    //             if (a.criteriaId < b.criteriaId) {
    //                 return -1;
    //             }
    //             if (a.criteriaId > b.criteriaId) {
    //                 return 1;
    //             }
    //             return 0;
    //         });
    //         console.log(dataSort);


    //         const series = dataSort.map((item: any) => item.setOfQuestions)
    //         console.log(series);

    //         // Cho series thành mảng 1 chiều
    //         let series1D: any[] = []
    //         series.forEach((item) => {
    //             item.forEach((item1: any) => {
    //                 series1D.push(Math.round(item1.average * 10) / 10)
    //             })
    //         })
    //         console.log(series1D);
    //         setLstSeries(series1D)

    //         let labels: any[] = []
    //         series.forEach((item) => {
    //             item.forEach((item1: any) => {
    //                 labels.push(item1.setOfQuestionDes)
    //             })
    //         })
    //         console.log(labels);


    //         // Tạo ra mảng màu theo số lượng series, mỗi series[0], series[1] có 1 màu, còn serti[0][0] sẽ trùng với màu của series[0]
    //         let colors: string[] = []
    //         series.forEach((item, index) => {
    //             // Lấy ra màu ngẫu nhiêu để cho vào mảng colors
    //             item.forEach(() => {
    //                 // colors.push('#FF0000')
    //                 // colors sẽ lấy ra màu ngẫu nhiên
    //                 colors.push(lstColor[index])
    //             })
    //         })
    //         console.log(colors);

    //         const options = {
    //             responsive: true,
    //             scales: {
    //                 r: {
    //                     suggestedMin: 0,
    //                     suggestedMax: 5,
    //                     grid: {
    //                         circular: true,
    //                         colors: '#000',
    //                     },
    //                     angleLines: {
    //                         display: true,
    //                         color: '#00000',
    //                         lineWidth: 1,
    //                     },
    //                     ticks: {
    //                         stepSize: 1,
    //                         beginAtZero: true,
    //                         backdropColor: 'transparent',
    //                         font: {
    //                             size: 19,
    //                         },
    //                         color: '#000',
    //                     },
    //                     lineArc: true,

    //                 },
    //             },
    //             plugins: {
    //                 legend: {
    //                     display: false
    //                 },

    //                 dataLabels: {
    //                     // color: '#000',
    //                     display: false,

    //                     // formatter: (value: any, context: any) => {
    //                     //     return ''; // Display the value in the label
    //                     // },
    //                 },
    //             },
    //             cutout: '50%',
    //             layout: {
    //                 padding: 5
    //             },
    //             hover: {
    //                 mode: 'nearest'
    //             },
    //             animation: {
    //                 duration: 2000
    //             },
    //             tooltips: {
    //                 enabled: true,
    //             },
    //         };

    //         const optionsDonut = {
    //             cutout: '80%', // Adjust the size of the inner hole
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             plugins: {
    //                 legend: {
    //                     display: false,
    //                 },
    //                 datalabels: {
    //                     color: (context: any) => {
    //                         return lstColor[context.dataIndex];
    //                     },
    //                     rotation: (context: any) => {
    //                         switch (context.dataIndex) {
    //                             case 0:
    //                                 return 15;
    //                             case 1:
    //                                 return 55;
    //                             case 2:
    //                                 return 100;
    //                             case 3:
    //                                 return 155;
    //                             case 4:
    //                                 return 200;
    //                             case 5:
    //                                 return 245;
    //                             case 6:
    //                                 return 290;
    //                             case 7:
    //                                 return 335;
    //                             default:
    //                                 return 0;
    //                         }
    //                     },
    //                     display: true,

    //                     formatter: (value: any, context: any, index: any) => {
    //                         console.log(context.dataIndex);
    //                         console.log(dataSort[context.dataIndex].criteriaName);
    //                         //Kiểm tra nếu
    //                         let label = dataSort[context.dataIndex].criteriaName;
    //                         if (label.length > 30) {
    //                             label = label.substring(0, 30) + '...';
    //                         }
    //                         return label; // Display the value in the label
    //                     },
    //                     font: {
    //                         size: windowSize[0] > 400 ? 12 : 6, // Set the font size for data labels
    //                         fontWeight: 'bold',
    //                     },
    //                 },
    //             },
    //         }

    //         const optionsRadio = {
    //             responsive: true,
    //             scales: {
    //                 r: {
    //                     suggestedMin: 0,
    //                     suggestedMax: 5,
    //                     grid: {
    //                         display: true
    //                     },
    //                     angleLines: {
    //                         display: true
    //                     },
    //                     ticks: {
    //                         display: true,
    //                         stepSize: 1 // Đặt khoảng cách các số liệu là 1 đơn vị
    //                     },
    //                     lineArc: true,

    //                     pointLabels: {
    //                         display: false, // Ẩn nhãn xung quanh biểu đồ
    //                     },
    //                     // grid: {
    //                     //     display: false, // Tùy chọn: Ẩn các đường lưới
    //                     // },
    //                     // angleLines: {
    //                     //     display: false, // Tùy chọn: Ẩn các đường hướng tâm
    //                     // },
    //                     // ticks: {
    //                     //     display: false, // Tùy chọn: Ẩn các chỉ số mức độ
    //                     //<|end_header|>

    //                 },
    //             },
    //             plugins: {
    //                 legend: {
    //                     display: false
    //                 },

    //                 dataLabels: {
    //                     // color: '#000',
    //                     display: false,

    //                     // formatter: (value: any, context: any) => {
    //                     //     return ''; // Display the value in the label
    //                     // },
    //                 },

    //             },
    //         }

    //         const dataTmp = {
    //             labels: labels,
    //             datasets: [
    //                 {
    //                     label: 'Điểm: ',
    //                     data: series1D,
    //                     backgroundColor: colors,
    //                     borderColor: '#000',
    //                     borderWidth: 1,
    //                     innerRadius: 50,
    //                     indexAxis: 'r',
    //                 },
    //             ],
    //         }

    //         const dataDonutTmp = {
    //             labels: dataSort.map((item: any) => item.criteriaName),
    //             datasets: [
    //                 {
    //                     label: 'Điểm: ',
    //                     data: [5, 5, 5, 5, 5, 5, 5, 5],
    //                     backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
    //                     borderColor: '#000',
    //                     borderWidth: 1,
    //                     innerRadius: 90,
    //                     indexAxis: 'r',
    //                 },
    //             ],
    //         }

    //         const tmpDataRadio: any[] = []

    //         data1.statisticOfFacilityLong && data1.statisticOfFacilityLong.listAverageOfCriterias.map((item: any) => {
    //             tmpDataRadio.push(Math.round(item.averageOfCriteria * 10) / 10)
    //         })

    //         const tmp2DataRadio: any[] = []

    //         data1.statisticOfFacilityShort && data1.statisticOfFacilityShort.listAverageOfCriterias.map((item: any) => {
    //             tmp2DataRadio.push(Math.round(item.averageOfCriteria * 10) / 10)
    //         })

    //         const dataRadioTmp = {
    //             labels: data1.statisticOfFacilityLong && data1.statisticOfFacilityLong.listAverageOfCriterias.map((item: any) => item.criteriaName),
    //             datasets: [
    //                 {
    //                     label: 'Điểm: ',
    //                     data: tmpDataRadio,
    //                     backgroundColor: ['rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)'],
    //                     borderColor: '#000',
    //                     borderWidth: 1,
    //                     innerRadius: 50,
    //                     indexAxis: 'r',
    //                 },
    //             ],
    //         }

    //         // data.map((item: any, index: any) => {
    //         //     let average = 0
    //         //     item.setOfQuestions.map((item1: any, index1: any) => {
    //         //         average += item1.average
    //         //     })
    //         //     average = average / item.setOfQuestions.length

    //         //     average = Math.round(average * 10) / 10;
    //         //     tmpDataRadio.push(average)
    //         // })

    //         // const dataRadioTmp = {
    //         //     labels: dataSort.map((item: any) => item.criteriaName),
    //         //     datasets: [
    //         //         {
    //         //             label: 'Điểm: ',
    //         //             data: tmpDataRadio,
    //         //             backgroundColor: ['rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)', 'rgba(253, 253, 246, 0.5)'],
    //         //             borderColor: '#000',
    //         //             borderWidth: 1,
    //         //             innerRadius: 50,
    //         //             indexAxis: 'r',
    //         //         },
    //         //     ],
    //         // }

    //         // console.log(dataRadioTmp);


    //         // setOptions(options)
    //         // setOptionsDonut(optionsDonut)
    //         // setDataDonut(dataDonutTmp)
    //         // setLstData(dataTmp)
    //         // setDataRadio(dataRadioTmp)
    //         // setOptionsRadio(optionsRadio)

    //     }

    // }, [data1, data])

    const fetchDataSystemStatistics = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.replace(/"/g, '');
        }

        console.log(checkLogin);

        await axios.post(`${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GET_DETAIL_USER}?type=${user?.type}&year=${yearSelected}`,
            {

            },
            {
                headers: {
                    acccept: 'application/json',
                    Authorization: `Bearer ${checkLogin}`
                }
            })
            .then((res) => {
                console.log(res.data.data);
                const dataSort = res.data.data.sort((a: any, b: any) => {
                    if (a.criteriaId < b.criteriaId) {
                        return -1;
                    }
                    if (a.criteriaId > b.criteriaId) {
                        return 1;
                    }
                    return 0;
                });

                console.log(dataSort);


                setData(dataSort)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const fetchNumberUser = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.replace(/"/g, '');
        }

        console.log(checkLogin);

        await axios.get(`${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GET_RESULT_LONG_SHORT}/${yearSelected}`,
            {
                headers: {
                    acccept: 'application/json',
                    Authorization: `Bearer ${checkLogin}`
                }
            })
            .then((res) => {
                console.log('data1', res.data.data);

                setData1(res.data.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getTottalUser = () => {

        let total = 0

        if (data1 && data1.statisticOfFacilityLong && data1.statisticOfFacilityLong.totalOfResult) {
            total += data1.statisticOfFacilityLong.totalOfResult
        }

        if (data1 && data1.statisticOfFacilityShort && data1.statisticOfFacilityShort.totalOfResult) {
            total += data1.statisticOfFacilityShort.totalOfResult
        }

        return total
    }

    const getTotalUserLong = () => {
        let total = 0
        if (data1 && data1.statisticOfFacilityLong && data1.statisticOfFacilityLong.totalOfResult) {
            total += data1.statisticOfFacilityLong.totalOfResult
        }

        return total
    }

    const getTotalUserShort = () => {
        let total = 0
        if (data1 && data1.statisticOfFacilityShort && data1.statisticOfFacilityShort.totalOfResult) {
            total += data1.statisticOfFacilityShort.totalOfResult
        }

        return total
    }


    return (
        <Spin spinning={!lstData || !dataDonut} size='large'>
            <div className='main-statistics'>
                <div className='user-statistics'>
                    <div className='usermanagement-title'>
                        {(userType === 'UINNOVATE' || userType === 'UIMPACT') && <h5 className={`${userType}`}> Kết quả đánh giá trường đại học khởi nghiệp</h5>}
                        {userType === '6' && <h5 className={`${userType}`}> Kết quả đánh giá địa phương</h5>}
                        {((userType === 'UINNOVATE' || userType === 'UIMPACT') && user) &&
                            <div className='subtitle'>Trường được đánh giá: <b>{user.facility.name}</b></div>
                        }
                        {(userType === 'PINNOVATE' && user && user.address) &&
                            <div className='subtitle'>Địa phương được đánh giá: <b>{user.address.name}</b></div>
                        }
                        {(userType === 'UINNOVATE') &&
                            <div style={{
                                margin: '20px 0',
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '10px'
                            }}>
                                <div>
                                    Tổng số người đã tham gia đánh giá: <b>
                                        {getTottalUser()}
                                    </b>
                                </div>

                                <div>
                                    Tổng số long-form được đánh giá: <b>
                                        {getTotalUserLong()}
                                    </b>
                                </div>

                                <div>
                                    Tổng số short-form được đánh giá:  <b>
                                        {getTotalUserShort()}
                                    </b>
                                </div>

                            </div>
                        }
                        {/* <div className='subtitle'>Tổng số người đã tham gia đánh giá:<b> {systemStatistics?.data.totalOfResult}</b></div> */}
                    </div>
                    <div className='usermanagement-filter'>
                        <Select
                            placeholder="Chọn năm"
                            defaultValue={'2025'}
                            onChange={(value) => setYearSelected(value)}
                        >
                            <Select.Option value="2023">2023</Select.Option>
                            <Select.Option value="2024">2024</Select.Option>
                            <Select.Option value="2025">2025</Select.Option>
                        </Select>
                    </div>

                    <div className='charts'>
                        <div
                            className='title-chart'
                        >
                            Biểu đồ đánh giá tổng thể của bạn
                        </div>

                        {((lstData && dataDonut)) &&
                            <div className="charts">
                                <div className='chart'>
                                    <div className="chart-polar">
                                        <PolarAreaChart data={lstData} options={options} />
                                        <div className="logo-chart">
                                            <img src={Logo} alt="" />
                                        </div>
                                    </div>
                                    <div className="chart-dough">
                                        <DoughnutChart data={dataDonut} options={optionsDonut} />
                                    </div>
                                    {/* <SunburstChart data={data1} /> */}
                                </div>

                                {/* <div className='labels'>
                                {
                                    systemStatistics?.data?.listAverageOfCriterias?.map((item: any, index: any) => {
                                        return (
                                            <div className='labels__item' key={index}>
                                                <div className='labels__item__color' style={{ backgroundColor: lstColor[index] }}></div>
                                                <div className='labels__item__text'>
                                                    {item.criteriaName}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div> */}
                            </div>
                        }

                    </div>

                    <div className='charts'>
                        {/* <div
                            className='title-chart'
                        >
                            Đánh giá của bạn so với trung bình hệ thống
                        </div> */}
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-around',
                                // gap: '200px'
                            }}
                        >

                            {((lstData && dataDonut && dataRadio1 && dataRadio2)) &&
                                <div className="charts">
                                    <div style={{
                                        marginBottom: '30px'

                                    }}>
                                        Đánh giá của bạn so với trung bình hệ thống
                                    </div>
                                    <div className="charts">
                                        {/* ...existing chart components... */}

                                        {lstChartData.length > 0 && (
                                            <div className="radar-amchart"
                                                style={{
                                                    width: 580,
                                                    height: 580,
                                                }}
                                            >
                                                {/* <div style={{ marginBottom: '30px' }}>
                                                    Biểu đồ radar thống kê đánh giá
                                                </div> */}
                                                <RadarAmChart lstChart={lstChartData} />
                                                <div className='labels'>
                                                    <div className='labels__item'>
                                                        <div className='labels__item__color' style={{ backgroundColor: '#6794dc' }}></div>
                                                        <div className='labels__item__text'>Trung bình longform</div>
                                                    </div>
                                                    <div className='labels__item'>
                                                        <div className='labels__item__color' style={{ backgroundColor: '#67dc94' }}></div>
                                                        <div className='labels__item__text'>Trung bình shortform</div>
                                                    </div>
                                                    <div className='labels__item'>
                                                        <div className='labels__item__color' style={{ backgroundColor: '#dc6794' }}></div>
                                                        <div className='labels__item__text'>Đánh giá của bạn</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* <div className='labels'>

                                        <div className='labels__item' >
                                            <div className='labels__item__color' style={{ backgroundColor: 'rgba(254, 246, 169, 0.5)' }}></div>
                                            <div className='labels__item__text'>
                                                Trung bình longform
                                            </div>
                                        </div>

                                        <div className='labels__item' >
                                            <div className='labels__item__color' style={{ backgroundColor: 'rgba(189, 248, 239, 0.5)' }}></div>
                                            <div className='labels__item__text'>
                                                Trung bình shortform
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            }

                            {((lstData && dataDonut && dataRadio1 && dataRadio2)) &&
                                <div className="charts">
                                    <div style={{
                                        marginTop: '32px',
                                    }}>
                                        Biểu đồ trung bình tất cả đánh giá trên hệ thống
                                    </div>
                                    <div className='chart'
                                        style={{ width: 580, height: 580 }}
                                    >

                                        {singleChartData.length > 0 && (
                                            <div className="radar-amchart-single" >
                                                <RadarAmChartSingle
                                                    data={singleChartData}
                                                    color="rgba(252, 89, 126, 0.5)"
                                                />
                                            </div>
                                        )}
{/*                                     
                                        <div className="chart-dough">
                                            <DoughnutChart data={data1Donut} options={optionsDonut} />
                                        </div>

                                        <div className="chart-radar"
                                            style={{
                                                width: 480,
                                                height: 480,
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)'
                                            }}
                                        >
                                            <RadarChart data={dataRadio3} options={optionsRadio3} />
                                        </div>

                                        {/* <SunburstChart data={data1} /> */}
                                    </div>

                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        <div

                                            className='labels__item'
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: '10px'
                                            }}
                                        >
                                            <div className='labels__item__color'
                                                style={{
                                                    backgroundColor: 'rgba(252, 89, 126, 0.5)',
                                                    width: 20,
                                                    height: 20,

                                                }}
                                            ></div>
                                            <div className='labels__item__text'
                                                style={{
                                                    fontSize: '1em',
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Trung bình trọng số longform và shortform
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }


                        </div>

                    </div>

                </div >
            </div>
        </Spin>
    )
}

export default UserStatistics
